/**
 * @fileoverview gRPC-Web generated client stub for language
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/language/language.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_language_language_pb from '../../protobuf/language/language_pb';


export class LanguageServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpsert = new grpcWeb.MethodDescriptor(
    '/language.LanguageService/Upsert',
    grpcWeb.MethodType.UNARY,
    protobuf_language_language_pb.UpsertLanguageRequest,
    protobuf_language_language_pb.LanguageResponse,
    (request: protobuf_language_language_pb.UpsertLanguageRequest) => {
      return request.serializeBinary();
    },
    protobuf_language_language_pb.LanguageResponse.deserializeBinary
  );

  upsert(
    request: protobuf_language_language_pb.UpsertLanguageRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_language_language_pb.LanguageResponse>;

  upsert(
    request: protobuf_language_language_pb.UpsertLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_language_language_pb.LanguageResponse) => void): grpcWeb.ClientReadableStream<protobuf_language_language_pb.LanguageResponse>;

  upsert(
    request: protobuf_language_language_pb.UpsertLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_language_language_pb.LanguageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/language.LanguageService/Upsert',
        request,
        metadata || {},
        this.methodDescriptorUpsert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/language.LanguageService/Upsert',
    request,
    metadata || {},
    this.methodDescriptorUpsert);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/language.LanguageService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_language_language_pb.ListLanguagesRequest,
    protobuf_language_language_pb.ListLanguagesResponse,
    (request: protobuf_language_language_pb.ListLanguagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_language_language_pb.ListLanguagesResponse.deserializeBinary
  );

  list(
    request: protobuf_language_language_pb.ListLanguagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_language_language_pb.ListLanguagesResponse>;

  list(
    request: protobuf_language_language_pb.ListLanguagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_language_language_pb.ListLanguagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_language_language_pb.ListLanguagesResponse>;

  list(
    request: protobuf_language_language_pb.ListLanguagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_language_language_pb.ListLanguagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/language.LanguageService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/language.LanguageService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorUpsertTranslation = new grpcWeb.MethodDescriptor(
    '/language.LanguageService/UpsertTranslation',
    grpcWeb.MethodType.UNARY,
    protobuf_language_language_pb.UpsertLanguageTranslationRequest,
    protobuf_language_language_pb.LanguageTranslationResponse,
    (request: protobuf_language_language_pb.UpsertLanguageTranslationRequest) => {
      return request.serializeBinary();
    },
    protobuf_language_language_pb.LanguageTranslationResponse.deserializeBinary
  );

  upsertTranslation(
    request: protobuf_language_language_pb.UpsertLanguageTranslationRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_language_language_pb.LanguageTranslationResponse>;

  upsertTranslation(
    request: protobuf_language_language_pb.UpsertLanguageTranslationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_language_language_pb.LanguageTranslationResponse) => void): grpcWeb.ClientReadableStream<protobuf_language_language_pb.LanguageTranslationResponse>;

  upsertTranslation(
    request: protobuf_language_language_pb.UpsertLanguageTranslationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_language_language_pb.LanguageTranslationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/language.LanguageService/UpsertTranslation',
        request,
        metadata || {},
        this.methodDescriptorUpsertTranslation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/language.LanguageService/UpsertTranslation',
    request,
    metadata || {},
    this.methodDescriptorUpsertTranslation);
  }

  methodDescriptorListTranslations = new grpcWeb.MethodDescriptor(
    '/language.LanguageService/ListTranslations',
    grpcWeb.MethodType.UNARY,
    protobuf_language_language_pb.ListLanguageTranslationsRequest,
    protobuf_language_language_pb.ListLanguageTranslationsResponse,
    (request: protobuf_language_language_pb.ListLanguageTranslationsRequest) => {
      return request.serializeBinary();
    },
    protobuf_language_language_pb.ListLanguageTranslationsResponse.deserializeBinary
  );

  listTranslations(
    request: protobuf_language_language_pb.ListLanguageTranslationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_language_language_pb.ListLanguageTranslationsResponse>;

  listTranslations(
    request: protobuf_language_language_pb.ListLanguageTranslationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_language_language_pb.ListLanguageTranslationsResponse) => void): grpcWeb.ClientReadableStream<protobuf_language_language_pb.ListLanguageTranslationsResponse>;

  listTranslations(
    request: protobuf_language_language_pb.ListLanguageTranslationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_language_language_pb.ListLanguageTranslationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/language.LanguageService/ListTranslations',
        request,
        metadata || {},
        this.methodDescriptorListTranslations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/language.LanguageService/ListTranslations',
    request,
    metadata || {},
    this.methodDescriptorListTranslations);
  }

}

